import { storeToRefs } from 'pinia'

import { useSyncReferralCode } from '~/composables/useSyncReferralCode'

import { useCartStore } from '~/stores/cart'
import { useCartPromoStore } from '~/stores/cartPromo'
import { useDraftStore } from '~/stores/draft'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscBandSignupReferralStore } from '~/stores/miscBandSignupReferral'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath, $pinia } = useNuxtApp()
  const { syncReferralCode, userIsEligibleToReferralPromo } =
    useSyncReferralCode()

  const {
    id: DRAFT_ID,
    influencers: DRAFT_INFLUENCERS,
    influencers_count: DRAFT_INFLUENCER_COUNT,
    track: TRACK_ID,
  } = storeToRefs(useDraftStore($pinia))
  const { code: BAND_SIGNUP_REFERRAL_CODE } = storeToRefs(
    useMiscBandSignupReferralStore($pinia),
  )

  const { UPDATE_GROOVIZ } = useUserStore($pinia)
  const { SET_CURRENT: SET_CURRENT_DRAFT, GET_CURRENT_DRAFT_INFLUENCERS } =
    useDraftStore($pinia)
  const { FETCH: FETCH_DRAFT_TRACK } = useDraftTrackStore($pinia)
  const { REMOVE_PROMO_FROM_CART } = useCartStore($pinia)
  const { FETCH_SET: FETCH_INFLUENCER_SET } = useInfluencersStore($pinia)
  const { FETCH: FETCH_TAGS } = useTagStore($pinia)

  const draftIdToGet = to.params.id || to.query.draftId || undefined
  const storeDraftDoesNotMatchUrlDraft: boolean =
    !!draftIdToGet && DRAFT_ID.value !== Number(draftIdToGet)
  const currentDraftNeedsToLoadInfluencers: boolean =
    DRAFT_INFLUENCER_COUNT.value !== DRAFT_INFLUENCERS.value.length

  async function fillInfluencers() {
    // get the first batch of draft infs, but not all of them so that pages can load
    await GET_CURRENT_DRAFT_INFLUENCERS()
    await FETCH_INFLUENCER_SET(DRAFT_INFLUENCERS.value)
    // continue to get the rest of the influencers if there are any without blocking the page
    GET_CURRENT_DRAFT_INFLUENCERS({ fetchAll: true })
  }

  async function handleDraftMismatch() {
    REMOVE_PROMO_FROM_CART()
    await SET_CURRENT_DRAFT(Number(draftIdToGet))
    await fillInfluencers()
  }

  if (storeDraftDoesNotMatchUrlDraft) {
    await handleDraftMismatch().catch(() => {
      navigateTo($localePath('/'))
    })
  } else if (currentDraftNeedsToLoadInfluencers) {
    await fillInfluencers().catch(() => {
      navigateTo($localePath('/'))
    })
  }

  await UPDATE_GROOVIZ().catch()

  await Promise.all([
    callOnce('FETCH_TAGS', FETCH_TAGS),
    syncReferralCode(),
  ]).catch(() => true)
  if (TRACK_ID.value) await FETCH_DRAFT_TRACK(TRACK_ID.value)

  // TODO: Check if still needed - Try to apply referral code after fetching progressive promos
  // if (userIsEligibleToReferralPromo && BAND_SIGNUP_REFERRAL_CODE.value)
  //   await FETCH_CART_PROMO(BAND_SIGNUP_REFERRAL_CODE.value)
})
